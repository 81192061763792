<template>
  <div class="content-area">
    <hero-bar>
      İş Adet Raporu
      <b-tooltip slot="right" :label="$t('refresh')" type="is-dark" position="is-bottom">
        <b-button
          class="mr-10"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': dataFetchLoading }"
          icon-left="refresh"
          @click="refresh"
        />
      </b-tooltip>
      <b-tooltip slot="right" :label="$t('exportExcel')" type="is-dark" position="is-bottom">
        <b-button
          class="is-hidden-mobile"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': excelExportLoading }"
          icon-left="file-excel"
          @click="$refs.isAdetRaporuTable.excelExport(); excelExportLoading = true;"
        />
      </b-tooltip>
    </hero-bar>
    <section class="section is-main-section">
      <card-component class="has-mobile-sort-spaced mb-4">
        <b-field horizontal label="Tarih Aralığı">
          <b-datepicker
            v-model="dates"
            placeholder="Tarih Aralığı"
            :month-names="$t('dateTimePicker.monthNames')"
            :day-names="$t('dateTimePicker.dayNames')"
            locale="tr-TR"
            trap-focus
            expanded
            range
            @input="updateDates"
          />
        </b-field>
      </card-component>
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <is-adet-raporu-table
          ref="isAdetRaporuTable"
          :dates="dates"
          @excelExport="excelExport"
          @dataFetch="dataFetch"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import IsAdetRaporuTable from '@/components/IsAdetRaporuTable'
import moment from 'moment'

export default {
  title: 'İş Adet Raporu',
  components: {
    HeroBar,
    CardComponent,
    IsAdetRaporuTable,
  },
  data () {
    return {
      excelExportLoading: false,
      dataFetchLoading: false,
      dates: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
    }
  },
  methods: {
    refresh () {
      this.dataFetchLoading = true
      this.$refs.isAdetRaporuTable.getData()
    },
    excelExport (e) {
      setTimeout(() => {
        this.excelExportLoading = e
      }, 500)
    },
    dataFetch (e) {
      setTimeout(() => {
        this.dataFetchLoading = e
      }, 500)
    },
    updateDates () {
      if (this.dates.length === 2 && moment(this.dates[1]).diff(this.dates[0], 'days') < 31) {
        return
      }

      this.$buefy.toast.open({
        message: 'Lütfen en fazla 1 ay aralık seçiniz.',
        type: 'is-danger',
        queue: false,
      })

      const from_date = this.dates[0] || new Date
      const to_date = moment(from_date).add(30, 'days').toDate()

      this.dates = [from_date, to_date]
    },
  },
}
</script>