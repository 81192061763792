<template>
  <div>
    <b-table
      :data="data"
      :loading="isLoading"

      :current-page.sync="page"
      :total="total"
      :per-page="perPage"

      default-sort-direction="desc"
      :default-sort="[sortField, sortOrder]"
      sticky-header
      paginated
      hoverable
      backend-sorting
      backend-pagination

      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"

      class="no-wrap"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column v-slot="props" label="Tarih" centered>
        {{ props.row.date | localeDate }}
      </b-table-column>

      <b-table-column v-slot="props" label="Transfer Talep Sayısı" centered>
        <small class="tag is-abbr-like">
          {{ props.row.transfers_count }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Transfer Araç Adedi" centered>
        <small class="tag is-abbr-like">
          {{ props.row.cars_count_in_transfers }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Transfer Ortalama" centered>
        <small v-if="props.row.transfers_average" class="tag is-abbr-like">
          {{ props.row.transfers_average }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Shuttle Talep Sayısı" centered>
        <small class="tag is-abbr-like">
          {{ props.row.shuttle_registers_count }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Shuttle Araç Adedi" centered>
        <small class="tag is-abbr-like">
          {{ props.row.cars_count_in_shuttle_registers }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Shuttle Ortalama" centered>
        <small v-if="props.row.shuttle_registers_average" class="tag is-abbr-like">
          {{ props.row.shuttle_registers_average }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Toplam Personel" centered>
        <small class="tag is-abbr-like">
          {{ props.row.transfers_count + props.row.shuttle_registers_count }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Çalışan Sürücü Adedi" centered>
        <small class="tag is-abbr-like">
          {{ props.row.drivers_count }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Çalışan Araç Adedi" centered>
        <small class="tag is-abbr-like">
          {{ props.row.cars_count }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Günlük KM" centered>
        <small class="tag is-abbr-like">
          {{ props.row.km }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Genel Ortalama" centered>
        <small v-if="props.row.average" class="tag is-abbr-like">
          {{ props.row.average }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Kurum Araç Adedi" centered>
        <small class="tag is-abbr-like">
          {{ props.row.kurum_cars_count }}
        </small>
      </b-table-column>

      <b-table-column v-slot="props" label="Tedarik Araç Adedi" centered>
        <small class="tag is-abbr-like">
          {{ props.row.diger_cars_count }}
        </small>
      </b-table-column>

      <template slot="bottom-left">
        <div v-if="total > 0" class="has-text-left subtitle help">
          {{ $t('paginationShowing', [total.toLocaleString(), ((page * perPage) - perPage) + 1, ((page * perPage) > total ? total : (page * perPage)) ]) }}
        </div>
      </template>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>{{ $t('fetching') }}...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="table" size="is-large" />
            </p>
            <p>{{ $t('nodata') }}</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import download from 'downloadjs'

export default {
  props: {
    dates: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      isLoading: false,
      page: 1,
      total: 0,
      perPage: 20,
      sortField: 'id',
      sortOrder: 'desc',
      data: [],
    }
  },
  watch: {
    dates (newDates, oldDates) {
      if (!isEqual(newDates, oldDates)) {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData (exportAsExcel = false) {
      const params = {
        dates: this.dates.map((date) => moment(date).format('YYYY-MM-DD')),
        excel: exportAsExcel ? 1 : 0,
      }

      const responseType = exportAsExcel ? 'blob' : 'json'

      this.isLoading = true

      this.$http.get('/is-adet-raporu', { params, responseType }).then(({ data }) => {
        if (exportAsExcel) {
          const mimType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          const fileName = `is-adet-raporu-${moment().format('DD-MM-YYYY-h-mm')}.xlsx`
          download(data, fileName, mimType)
          this.isLoading = false
          this.$emit('excelExport', false)
          return
        }

        this.data = data
      }).catch(() => {
        if (!exportAsExcel) {
          this.data = []
        }

        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-danger',
        })
      }).finally(() => {
        this.isLoading = false
        this.$emit('dataFetch', false)
      })
    },
    average (value1, value2) {
      value1 = parseFloat(value1)
      value2 = parseFloat(value2)

      if (isNaN(value1) || isNaN(value2)) {
        return ''
      }

      return ((value1 + value2) / 2).toFixed(2)
    },
    onPageChange (page) {
      this.page = page
      this.getData()
    },
    onSort (field, order) {
      this.page = 1
      this.sortField = field
      this.sortOrder = order
      this.getData()
    },
    excelExport () {
      return this.getData(true)
    },
  },
}
</script>
